.logo {
  position: absolute;
  left: 10px;
  top: 10px;
}

.serversHeader {
  position: absolute;
  font-size: 32px;
  top: 20px;
}

.authHeader {
  position: absolute;
  font-size: 32px;
  top: 20px;
}